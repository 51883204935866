<template>
  <div>
    <context-menu ref="rowContextMenu" :items="contextMenuItems" @clickData="onContextMenuClick($event)" />
    <Widget
      ref="widget"
      :height="height"
      :model="$store.getters['config/get'].models.objectReports.listDash"
      :defaults="{ paramName: 'dashBoard.lastReport', sort: {}, filters: hideViewed ? { viewedon: '0' } : {} }"
      :sql="sql"
      @click="onClick($event)"
      :isLoading="loading"
      @loading="loading = $event"
      @rowContextMenu="onRowContextMenu($event)"
    >
      <template v-slot:title>
        Последние отчеты
        <v-icon small @click="hideViewed = !hideViewed">{{ hideViewed ? "fas fa-eye-slash" : "fas fa-eye" }}</v-icon>
      </template>
    </Widget>
    <report-dialog v-if="showReportDialog" v-model="showReportDialog" :id="idReport" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/sqlTable"),
    reportDialog: () => import("./../dialogs/objectReportDialog"),
  },
  props: {
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      contentHeight: 10,
      hideViewed: false,
      showReportDialog: false,
      idReport: 0,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,
      array_object: [],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    contextMenuItems() {
      return [
        { name: "viewThis", title: `Отметить прочитанным` },
        { name: "viewAll", title: `Отметить прочитанными ВСЁ` },
      ];
    },

    sql() {
      let group = "";
      let order = "createdon desc";
      let where = ``;
      let sql = `
select * from (
SELECT o.name as object_name, cr.id, cr.object_id, cr.createdon, cr.photos, cr.description,
ifnull((SELECT 1 FROM vieweds WHERE 1=1 and target_name='construction_reports' AND target_id=cr.id and user_id=${this.$root.profile.id}),0) AS viewedon
FROM construction_reports cr
	LEFT JOIN objects o ON o.id=cr.object_id
where o.id in (${this.array_object.join(",")}) ${this.$root.profile.role > 20 ? "" : " and cr.client_visible=1"}
) as t
`;

      let res = { sql, order, group, where };
      return res;
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {
    showReportDialog(v) {
      if (!v) {
        this.itemShowClose();
      }
    },
  },
  methods: {
    async onContextMenuClick(e) {
      if (e.item.name == "viewThis") {
        await this.$axios.post("/mechti/construction-reports/set_viewed", { ids: [e.data.id] });
        this.$refs.widget.updateRowData(e.data.id);
      }
      if (e.item.name == "viewAll") {
        await this.$axios.post("/mechti/construction-reports/set_viewed");
        this.$refs.widget.$refs.tableWidget.updateData();
      }
    },
    onRowContextMenu(e) {
      this.$refs.rowContextMenu.showMenu(e.event, e.row);
    },
    onClick(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event, e);
        return;
      }
      this.idReport = e.row.id;
      this.showReportDialog = true;
    },
    itemShowClose() {
      this.loading = true;
      if (this.idReport) this.$refs.widget.updateRowData(this.idReport);
      this.loading = !true;
      this.idReport = 0;
    },

    async fitchData() {
      this.loading = true;
      let array_object = [-1];
      let filters = null;
      let resp;
      if (this.$root.profile.role == 20) {
        resp = await this.$axios.get("/mechti/objects", {});
      } else {
        let params = { select: "objects.id as id", leftJoin: "objects_access ON objects_access.object_id = objects.id", group: "objects.id" };

        if ([90, 1000].includes(this.$root.profile.role)) params.where = { "objects_access.user_id": this.$root.profile.id };
        if ([10].includes(this.$root.profile.role)) {
          console.log(
            this.$root.profile,
            this.$root.profile.clients.map(c => c.id),
          );
          params.where = { "objects.client_id": this.$root.profile.clients.map(c => c.id) };
        }
        resp = await this.$axios.post("/mechti/objects/report", params);
      }
      //console.log("work", resp);
      if (resp.data.data.length > 0) {
        resp.data.data.forEach(el => {
          array_object.push(el.id);
        });
      } else {
      }
      this.array_object = array_object;
      this.afterFitch();
      return;
      filters = { object_id: array_object };
      resp = await this.$axios.get("/mechti/construction-reports", {
        sort: { key: "id", order: "DESC" },
        params: { filters, sort: { key: "id", order: "DESC" } },
      });

      this.data = resp.data.data;
      this.afterFitch();
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
